import React, { useContext } from 'react';
import { CartContext } from '../CartContext/CartContext';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet-async';

const Cart = () => {
  const { cart, removeFromCart, updateQuantity, clearCart } = useContext(CartContext);

  const handleQuantityChange = (e, productId) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity > 0) {
      updateQuantity(productId, newQuantity);
    }
  };

  const handleIncrease = (productId) => {
    const product = cart.find((item) => item.id === productId);
    if (product) {
      updateQuantity(productId, product.quantity + 1);
      Swal.fire({
        icon: 'info',
        title: 'Quantity Updated!',
        text: `Increased quantity for ${product.name} to ${product.quantity + 1}.`,
        timer: 1500,
      });
    }
  };

  const handleDecrease = (productId) => {
    const product = cart.find((item) => item.id === productId);
    if (product && product.quantity > 1) {
      updateQuantity(productId, product.quantity - 1);
      Swal.fire({
        icon: 'info',
        title: 'Quantity Updated!',
        text: `Decreased quantity for ${product.name} to ${product.quantity - 1}.`,
        timer: 1500,
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Minimum Quantity Reached!',
        text: `${product.name} cannot have a quantity less than 1.`,
        timer: 1500,
      });
    }
  };

  const handleRemoveFromCart = (productId) => {
    const product = cart.find((item) => item.id === productId);
    removeFromCart(productId);
    Swal.fire({
      icon: 'success',
      title: 'Removed from Cart!',
      text: `${product.name} has been removed from your cart.`,
      timer: 1500,
    });
  };

  const handleClearCart = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will clear all items in your cart!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, clear it!',
    }).then((result) => {
      if (result.isConfirmed) {
        clearCart();
        Swal.fire('Cleared!', 'Your cart is now empty.', 'success');
      }
    });
  };

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  return (
    <>
      <Helmet>
        <title>Cart Page</title>
        <meta name="format-detection" content="telephone=no" />
        <meta name="keywords" content="Cart Page" />
        <meta name="description" content="Cart Page" />
      </Helmet>

      <section className="content-inner shop-account pt-5">
        <div className="container pt-5">
          <div className="row pt-5">
            <div className="col-lg-8">
              <div className="table-responsive">
                <table className="table check-tbl">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th></th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <img src={`${BASE_URL}${item.image}`} alt={item.name} className="img-thumbnail" />
                        </td>
                        <td>{item.name}</td>
                        <td>{item.sale_price || item.price}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <button
                              className="btn btn-sm btn-outline-secondary"
                              onClick={() => handleDecrease(item.id)}
                            >
                              -
                            </button>
                            <input
                              type="number"
                              className="form-control mx-2 ps-4"
                              value={item.quantity}
                              onChange={(e) => handleQuantityChange(e, item.id)}
                              style={{ width: '70px' }}
                              readOnly
                            />
                            <button
                              className="btn btn-sm btn-outline-secondary"
                              onClick={() => handleIncrease(item.id)}
                            >
                              +
                            </button>
                          </div>
                        </td>
                        <td>{(item.sale_price || item.price) * item.quantity}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => handleRemoveFromCart(item.id)}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mt-3 text-end">
                <button className="btn btn-danger" onClick={handleClearCart}>
                  Clear Cart
                </button>
              </div>
            </div>

            <div className="col-lg-4 p-5">
              <div className="card bg-light">
                <div className="card-body bg-black text-white">
                  <h5 className="title text-white">Cart Summary</h5>
                  <p className="title">
                    Total Items: {cart.reduce((total, item) => total + item.quantity, 0)}
                  </p>
                  <p className="title">
                    Total Price: Rs/
                    {cart
                      .reduce((total, item) => total + (item.sale_price || item.price) * item.quantity, 0)
                      .toFixed(2)}
                  </p>
                  <Link to="/checkout" className="btn btn-primary btn-block">
                    Proceed to Checkout
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
