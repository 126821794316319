import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules'; // Correct import
import { ClipLoader } from 'react-spinners';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

// CSS-in-JS Styles
const styles = {
  banner: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    padding: '10px',
    paddingTop: '55px',
  },
  slideImage: {
    width: '100%',
    height: '100%',
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: '#fff',
    background: 'rgba(0, 0, 0, 0.5)',
    padding: '20px',
    borderRadius: '10px',
  },
  title: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
  },
  description: {
    fontSize: '1.2rem',
    marginTop: '10px',
  },
  button: {
    marginTop: '20px',
    padding: '10px 20px',
    fontSize: '1rem',
    color: '#fff',
    backgroundColor: '#ff5722',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  spinnerContainer: {
    textAlign: 'center',
    marginTop: '100px',
  },
};

const Banner = () => {
  const [myData, setMyData] = useState([]);
  const [loading, setLoading] = useState(true);

  const BASE_URL = process.env.REACT_APP_Banner_URL; 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await RestClient.GetRequest(AppUrl.homebannerproduct);
        console.log('API Result:', result);
        setMyData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
        setMyData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div style={styles.spinnerContainer}>
        <ClipLoader color="#000" loading={true} size={60} />
        <p>Loading...</p>
      </div>
    );
  }

  if (myData.length === 0) {
    return <p style={{ textAlign: 'center', marginTop: '20px' }}></p>;
  }

  return (
    <div style={styles.banner} className='mt-4'>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        navigation
        autoplay={{ delay: 3000 }}
        loop={true}
        slidesPerView={1}
      >
        {myData.map((item, index) => (
          <SwiperSlide key={index}>
          <img
            src={`${BASE_URL}${item.banner}`}
            alt={`Banner ${item.id}`}
            style={styles.slideImage}
          />
        </SwiperSlide>
        
        ))}
      </Swiper>
    </div>
  );
};

export default Banner;
