import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import { ClipLoader } from "react-spinners";
import { Helmet } from "react-helmet-async";

const AllCategories = () => {
  const [myData, setMyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleProducts, setVisibleProducts] = useState(12);

  useEffect(() => {
    RestClient.GetRequest(AppUrl.Categories)
      .then((result) => {
        setMyData(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setMyData([]);
        setLoading(false);
      });
  }, []);

  const getProductImageUrl = (product) => {
  const BASE_URL = process.env.REACT_APP_CATEGORY_URL;

  // Check if product image exists, if not return the placeholder image
  return `${BASE_URL}${product.image || "/uploads/no-img.jpg"}`;
};


  const loadMoreProducts = () => {
    setVisibleProducts((prevVisible) => prevVisible + 12);
  };

  const styles = {
    reviewContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      marginBottom: "-5px",
      fontSize: "16px",
      color: "#555",
    },
    starsList: {
      display: "flex",
      listStyleType: "none",
      padding: 0,
      margin: "0 10px 0 0",
    },
    starItem: {
      fontSize: "15px",
      color: "#d3d3d3",
    },
    starFill: {
      color: "#FFD700",
    },
    ratingText: {
      color: "#333",
      fontSize: "15px",
    },
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "100px" }}>
        <ClipLoader color="#000" loading={true} size={60} />
        <p>Loading...</p>
      </div>
    );
  }

  return (

    <>
    <Helmet>
    <title>All Categories</title>

    <meta name="robots" content="index, follow" />
    <meta name="format-detection" content="telephone=no" />

    {/* Ensure keywords are populated */}
    <meta name="keywords" content="All Categories"/>

    {/* Ensure description is properly populated and of sufficient length */}
    <meta 
        name="description" 
        content="All Categories"
    />

</Helmet>
    

    <section className="content-inner mt-5">
      <div className="container">
        <div className="row justify-content-md-between align-items-start">
          <div className="col-lg-12 col-md-12">
            <div className="section-head style-1 m-b30 wow fadeInUp" data-wow-delay="0.2s">
              <div className="left-content">
                <h2 className="title text-center">Most Popular Categories</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix">
          <ul id="masonry" className="row g-xl-4 g-3">
            {myData.slice(0, visibleProducts).map((product, index) => {

              return (
                <li
                  className="card-container col-6 col-xl-3 col-lg-3 col-md-4 col-sm-6 Tops wow fadeInUp"
                  data-wow-delay="0.6s"
                  key={index}
                >
                  <div className="shop-card">
                    <Link to={`/category/${product.slug}`}>
                      <div className="dz-media mb-3">
                        <img
                          src={getProductImageUrl(product)}
                          alt={product.name || "Category Image"} width={500} height={500}
                        />
                      </div>
                      
                    </Link>
                    <div className="dz-content">
                      <h5 className="title">
                        <Link to={`/category/${product.slug}`}>
                          {product.name.slice(0, 15) + "..."}
                        </Link> 
                      </h5>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        {visibleProducts < myData.length && (
          <div className="text-center">
            <button
              className="btn btn-secondary"
              onClick={loadMoreProducts}
              style={{
                marginTop: "20px",
                padding: "10px 20px",
                fontSize: "16px",
              }}
            >
              Load More
            </button>
          </div>
        )}
      </div>
    </section>
    </>
  );
};

export default AllCategories;
