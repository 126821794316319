import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import ClipLoader from 'react-spinners/ClipLoader';

const Blogs = () => {
  const [myData, setMyData] = useState([]); // State to hold dynamic data
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    // Fetch data from your API
    const fetchBlogs = async () => {
      try {
        const result = await RestClient.GetRequest(AppUrl.HomeBlog);
        setMyData(result);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setMyData([]);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []); // Empty dependency array ensures this runs once on component mount

  const BLOG_URL = process.env.REACT_APP_BLOG_URL;

  // Show the spinner while loading
  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <ClipLoader color="#000" loading={true} size={60} />
        <p>Loading...</p>
      </div>
    );
  }

  // Handle empty data
  if (myData.length === 0) {
    return <div></div>;
  }

  const mainBlog = myData[0];
  const imageUrl = `${BLOG_URL}${mainBlog.image}`;

  return (
    <section className="content-inner">
      <div className="container">
        <div className="section-head style-1 wow fadeInUp d-md-flex justify-content-between align-items-center" data-wow-delay="0.1s">
          <div className="left-content">
            <h2 className="title">Latest Post</h2>
            <p>Discover the most trending Posts in DealKart.</p>
          </div>
          <a className="btn btn-secondary" href="blog-archive.html">View All</a>
        </div>
        <div className="row blog-shap">
          <div className="col-lg-6 col-md-12 col-sm-12 m-lg-b30 m-b50 wow fadeInUp" data-wow-delay="0.1s">
            <div className="dz-card style-1 light">
              <div className="dz-media">
                <img src={imageUrl} alt="/" loading="lazy" />
              </div>
              <div className="dz-info bg-white">
                <div className="dz-meta">
                  <ul>
                    <li className="post-date">{mainBlog.created_at}</li>
                  </ul>
                </div>
                <h3 className="dz-title">
                  <Link to={`/blog/${mainBlog.id}`}>{mainBlog.name}</Link>
                </h3>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 m-lg-b30 m-b50 wow fadeInUp" data-wow-delay="0.1s">
            <div className="row">
              {myData.slice(1, 3).map((blog, index) => (
                <div className="col-lg-12 col-md-6 m-b30" key={index}>
                  <div className="dz-card blog-half style-7">
                    <div className="dz-media">
                      <img src={`${BLOG_URL}${blog.image}`} alt="/" loading="lazy" />
                    </div>
                    <div className="dz-info">
                      <div className="dz-meta">
                        <ul>
                          <li className="post-date">{blog.created_at}</li>
                        </ul>
                      </div>
                      <h4 className="dz-title">
                        <Link to={`/blog/${blog.slug}`}>{blog.name}</Link>
                      </h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blogs;
