import React, { Component, Fragment } from 'react'
import Footer from '../components/Footer/Footer'
import TopBar from '../components/TopBar/Topbar'
import Checkout from '../components/Checkout/Checkout'

class CheckoutPage extends Component {
  render() {
    return (
      <Fragment>
        <TopBar />
        <Checkout />
        <Footer />
      </Fragment>
    )
  }
}

export default CheckoutPage