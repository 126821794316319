import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';

const Footer = () => {
    // Using useState to manage the footer data
    const [footerData, setFooterData] = useState({
        name: '...',
        logo: '...',
        email: '...',
        phone: '...',
        copy_right: '...'
    });

    // Fetching data when the component mounts using useEffect
    useEffect(() => {
        const fetchFooterData = async () => {
            try {
                const result = await RestClient.GetRequest(AppUrl.Setting);
                setFooterData({
                    name: result[0]?.name || '????',
                    logo: result[0]?.logo || '????',
                    email: result[0]?.email || '????',
                    phone: result[0]?.phone || '????',
                    copy_right: result[0]?.copy_right || '????'
                });
            } catch (error) {
                setFooterData({
                    name: '????',
                    logo: '????',
                    email: '????',
                    phone: '????',
                    copy_right: '????'
                });
            }
        };

        fetchFooterData();
    }, []);

    return (
        <footer className="site-footer footer-dark">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="widget widget_about me-2">
                                <div className="footer-logo logo-white">
                                    <h3>{footerData.name}</h3>
                                </div>
                                <ul className="widget-address nav-inline">
                                    <li>
                                        <p><span>E-mail</span> : {footerData.email}</p>
                                    </li>
                                    <li>
                                        <p><span>Phone</span> : {footerData.phone}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="widget widget_services text-lg-end">
                            <ul className="nav-inline mb-3">
                                    <li><Link to="/policy"><span>Privacy Policy</span></Link></li>
                                    <li><Link to="/shipping"><span>Shipping Policy</span></Link></li>
                                    <li><Link to="/terms"><span>Terms and Condition</span></Link></li>
                                </ul>
                                <ul className="nav-inline mb-3">
                                    <li><Link to="/"><span>Home</span></Link></li>
                                    <li><Link to="/shop"><span>Shop</span></Link></li>
                                    <li><Link to="/about"><span>About</span></Link></li>
                                    <li><Link to="/contact"><span>Contact</span></Link></li>
                                </ul>
                                
                                <ul className="nav-inline">
                                    <li><a href="" onClick={(e) => e.preventDefault()} target="_blank" className="site-button-link facebook hover"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="" onClick={(e) => e.preventDefault()} target="_blank" className="site-button-link instagram hover"><i className="fab fa-instagram"></i></a></li>
                                    <li><a href="" onClick={(e) => e.preventDefault()} target="_blank" className="site-button-link twitter hover"><i className="fab fa-twitter"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row fb-inner">
                        <div className="col-lg-12 col-md-12 text-center">
                            <p className="copyright-text">{footerData.copy_right}</p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
