import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import { CartContext } from '../CartContext/CartContext';

const TopBar = () => {
  const [logo, setLogo] = useState("default-logo.png"); // Default logo
  const { cartCount } = useContext(CartContext); // Access cartCount from context
  const SETTING_URL = process.env.REACT_APP_SETTING_URL;

  useEffect(() => {
    // Fetch logo from the Setting API
    const fetchLogo = async () => {
      try {
        const result = await RestClient.GetRequest(AppUrl.Setting);
        const fetchedLogo = result[0]?.logo || "default-logo.png"; // Fallback to default logo
        setLogo(fetchedLogo);
      } catch (error) {
        console.error("Error fetching settings:", error);
        setLogo("default-logo.png"); // Fallback on error
      }
    };
    fetchLogo();
  }, []); // Runs only once on mount

  const logoUrl = `${SETTING_URL}${logo}`; // Full logo URL
 
  return (
    <header className="site-header mo-left header style-1 header-transparent">
      <div className="main-bar-wraper navbar-expand-lg">
        <div className="main-bar clearfix">
          <div className="container-fluid clearfix d-lg-flex d-block">
            <div className="logo-header logo-dark me-md-5">
              <Link to="/">
                <img src={logoUrl} alt="logo" loading="lazy" />
              </Link>
            </div>

            <button
              className="navbar-toggler collapsed navicon justify-content-end"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>

            <div
              className="header-nav navbar-collapse collapse justify-content-start"
              id="navbarNavDropdown"
            >
              <ul className="nav navbar-nav">
                <li className="has-mega-menu sub-menu-down">
                  <Link to="/"><span>Home</span></Link>
                </li>
                <li className="has-mega-menu sub-menu-down">
                  <Link to="/shop"><span>Shop</span></Link>
                </li>
                <li className="has-mega-menu sub-menu-down">
                  <Link to="/categories"><span>Categories</span></Link>
                </li>
                <li className="has-mega-menu sub-menu-down">
                  <Link to="/about"><span>About</span></Link>
                </li>
                <li className="has-mega-menu sub-menu-down">
                  <Link to="/contact"><span>Contact</span></Link>
                </li>
              </ul>

              <div className="dz-social-icon">
                <ul>
                  <li>
                    <a className="fab fa-facebook-f" target="_blank" rel="noreferrer" href=""></a>
                  </li>
                  <li>
                    <a className="fab fa-twitter" target="_blank" rel="noreferrer" href=""></a>
                  </li>
                  <li>
                    <a className="fab fa-instagram" target="_blank" rel="noreferrer" href=""></a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="extra-nav">
              <div className="extra-cell">
                <ul className="header-right">
                  <li className="nav-item search-link">
                    <a
                      className="nav-link"
                      href="javascript:void(0);"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasTop"
                      aria-controls="offcanvasTop"
                    >
                      <i className="iconly-Light-Search"></i>
                    </a>
                  </li>
                  <li className="nav-item cart-link">
                    <Link to="/cart" className="nav-link cart-btn">
                      <i className="iconly-Broken-Buy"></i>
                      <span className="badge badge-circle">{cartCount}</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Link to="/cart">
  <button
    className="cart-btn"
    type="button"
    style={{
      position: 'fixed',    // Fix the button in place
      bottom: '20px',       // Position it 20px from the bottom
      right: '20px',        // Position it 20px from the right
      zIndex: 9999,         // Ensure it's above other content
      backgroundColor: 'black', // Optional, change as needed
      borderRadius: '50%',  // Optional, makes the button circular
      padding: '15px 15px', // Increase padding to make button bigger
      border: 'none',       // Remove default border
      boxShadow: '0 4px 8px rgba(0,0,0,0.2)', // Optional, adds shadow
      fontSize: '20px',     // Increase font size for the icon
      display: 'flex',      // Ensure button content is centered
      alignItems: 'center', // Vertically center the content
      justifyContent: 'center', // Horizontally center the content
    }}
  >
    <i className="fas fa-shopping-cart" style={{ fontSize: '20px', color:'white' }}></i> {/* Increase icon size */}
    <span style={{
      position: 'absolute',
      top: '-5px',
      right: '-5px',
      backgroundColor: 'orange',
      color: 'black',
      borderRadius: '50%',
      fontSize: '12px',
      padding: '2px 7px',
    }}>
      {cartCount}
    </span>
  </button>
</Link>

            

      <div
        className="dz-search-area dz-offcanvas offcanvas offcanvas-top"
        tabIndex="-1"
        id="offcanvasTop"
      >
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
          ×
        </button>
        <div className="container">
          <form className="header-item-search">
            <div className="input-group search-input">
              <input type="search" className="form-control" placeholder="Search Product" />
              <button className="btn" type="button">
                <i className="iconly-Light-Search"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </header>
    
  );
};

export default TopBar;
