import axios from 'axios';

class RestClient {
    // Default Axios configuration
    static axiosInstance = axios.create({
        baseURL: 'http://reactapi.test/api/', // Set your Laravel API base URL here
        timeout: 10000,
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true, // Ensure cookies are sent with requests
    });

    // GET request
    static GetRequest = async (getUrl, headers = {}) => {
        try {
            const response = await this.axiosInstance.get(getUrl, { headers });
            return response.data;
        } catch (error) {
            this.logError("GET", getUrl, null, headers, error);
            throw error;
        }
    };

    // POST request
    static PostRequest = async (postUrl, postJson, headers = {}) => {
        try {
            const response = await this.axiosInstance.post(postUrl, postJson, { headers });
            return response.data;
        } catch (error) {
            this.logError("POST", postUrl, postJson, headers, error);
            throw error;
        }
    };

    // PUT request
    static PutRequest = async (putUrl, putJson, headers = {}) => {
        try {
            const response = await this.axiosInstance.put(putUrl, putJson, { headers });
            return response.data;
        } catch (error) {
            this.logError("PUT", putUrl, putJson, headers, error);
            throw error;
        }
    };

    // DELETE request
    static DeleteRequest = async (deleteUrl, headers = {}) => {
        try {
            const response = await this.axiosInstance.delete(deleteUrl, { headers });
            return response.data;
        } catch (error) {
            this.logError("DELETE", deleteUrl, null, headers, error);
            throw error;
        }
    };

    // Error logging
    static logError = (method, url, data, headers, error) => {
        if (process.env.NODE_ENV !== 'production') {
            console.error(`[${method}] Request to ${url} failed`);
            console.error("Request Data:", data);
            console.error("Request Headers:", headers);
            console.error("Error:", error.response?.data || error.message || error);
        }
    };
}

export default RestClient;
