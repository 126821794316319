import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import { ClipLoader } from "react-spinners";

const Products = () => {
  const [myData, setMyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleProducts, setVisibleProducts] = useState(12);

  useEffect(() => {
    RestClient.GetRequest(AppUrl.homeproduct)
      .then((result) => {
        setMyData(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setMyData([]);
        setLoading(false);
      });
  }, []);

  const getProductImageUrl = (product) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const ImUrl = process.env.REACT_APP_IM_URL;

    if (product.image) {
      return `${ImUrl}${product.image}`;
    }

    return `${BASE_URL}${product.image || "/uploads/no-img.jpg"}`;
  };

  const calculateAverageRating = (reviews) => {
    if (!reviews || reviews.length === 0) return 0;
    const total = reviews.reduce((sum, { rating }) => sum + rating, 0);
    return (total / reviews.length).toFixed(1);
  };

  const loadMoreProducts = () => {
    setVisibleProducts((prevVisible) => prevVisible + 12);
  };

  const styles = {
    reviewContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      marginBottom: "-5px",
      fontSize: "16px",
      color: "#555",
    },
    starsList: {
      display: "flex",
      listStyleType: "none",
      padding: 0,
      margin: "0 10px 0 0",
    },
    starItem: {
      fontSize: "15px",
      color: "#d3d3d3",
    },
    starFill: {
      color: "#FFD700",
    },
    ratingText: {
      color: "#333",
      fontSize: "15px",
    },
  };

  const imageStyle = {
    width: "200px", // Adjust the width as per your layout
    height: "200px", // Set a fixed height
    objectFit: "cover", // Ensures the image scales without distortion
    borderRadius: "8px", // Optional: Add rounded corners
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "100px" }}>
        <ClipLoader color="#000" loading={true} size={60} />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <section className="content-inner">
      <div className="container">
        <div className="row justify-content-md-between align-items-start">
          <div className="col-lg-12 col-md-12">
            <div className="section-head style-1 m-b30 wow fadeInUp" data-wow-delay="0.2s">
              <div className="left-content">
                <h2 className="title text-center">Most Popular Products</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix">
          <ul id="masonry" className="row g-xl-4 g-3">
            {myData.slice(0, visibleProducts).map((product, index) => {
              const averageRating = calculateAverageRating(product.reviews);
              const reviewCount = product.reviews?.length || 0;

              return (
                <li
                  className="card-container col-6 col-xl-3 col-lg-3 col-md-4 col-sm-6 Tops wow fadeInUp"
                  data-wow-delay="0.6s"
                  key={index}
                >
                  <div className="shop-card">
                    <Link to={`/product/${product.slug}`}>
                      <div className="dz-media mb-3">
                        <img
                          src={getProductImageUrl(product)}
                          alt={product.name || "Product Image"} style={imageStyle}
                        />
                      </div>
                      <div className="review-num" style={styles.reviewContainer}>
                        <ul className="dz-rating " style={styles.starsList}>
                          {[...Array(5)].map((_, starIndex) => (
                            <li
                              key={starIndex}
                              style={{
                                ...styles.starItem,
                                ...(starIndex < Math.round(averageRating) ? styles.starFill : {}),
                              }}
                            >
                              <i className="flaticon-star-1"></i>
                            </li>
                          ))}
                        </ul>
                        <span className="text-secondary me-2" style={styles.ratingText}>
                          ({reviewCount} Reviews)
                        </span>
                      </div>
                    </Link>
                    <div className="dz-content">
                      <h5 className="title">
                        <Link to={`/product/${product.slug}`}>
                          {product.name.slice(0, 15) + "..."}
                        </Link>
                      </h5>
                      <span className="price">
                        {product.sale_price ? (
                          <>
                            Rs {product.sale_price} <del>Rs {product.price}</del>
                          </>
                        ) : (
                          <>Rs {product.price}</>
                        )}
                      </span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        {visibleProducts < myData.length && (
          <div className="text-center">
            <button
              className="btn btn-secondary"
              onClick={loadMoreProducts}
              style={{
                marginTop: "20px",
                padding: "10px 20px",
                fontSize: "16px",
              }}
            >
              Load More
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Products;
