import React, { useState, useEffect } from 'react';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import ReactHtmlParser from 'html-react-parser';
import { Helmet } from 'react-helmet-async';

const BlogDetail = ({ id }) => {
    // State hooks to manage blog data
    const [blogData, setBlogData] = useState({
        name: '...',
        slug: '...',
        image: '...',
        description: '...',
        meta_description: '...',
        meta_keywords: '...',
        created_at: '...',
    });

    // Fetch blog details on component mount
    useEffect(() => {
        RestClient.GetRequest(AppUrl.BlogDetail + id)
            .then(result => {
                const blog = result[0]; // Assuming result is an array
                setBlogData({
                    name: blog.name,
                    slug: blog.slug,
                    image: blog.image,
                    description: blog.description,
                    meta_description: blog.meta_description,
                    meta_keywords: blog.meta_keywords,
                    created_at: blog.created_at,
                });
            })
            .catch(error => {
                setBlogData({
                    name: '????',
                    slug: '????',
                    image: '????',
                    description: '????',
                    meta_description: '????',
                    meta_keywords: '????',
                    created_at: '????',
                });
            });
    }, [id]); // Run effect only when 'id' changes

    // Get the base URL for the image
    const BLOG_URL = process.env.REACT_APP_BLOG_URL;
    const imageUrl = `${BLOG_URL}`;

    return (
        <>

<Helmet>
    <title>{blogData && blogData.name ? `${blogData.name} | React` : 'Default Title'}</title>

    <meta name="robots" content="index, follow" />
    <meta name="format-detection" content="telephone=no" />

    {/* Ensure keywords are populated */}
    <meta name="keywords" content={blogData.meta_keywords || 'default, keywords, for, product'} />

    {/* Ensure description is properly populated and of sufficient length */}
    <meta 
        name="description" 
        content={blogData.meta_description}
    />

    {/* Ensure canonical URL is properly set */}
    <meta rel="canonical" href={`/product/${blogData?.slug || ''}`} />

</Helmet>

            <section className="shop-section overflow-hidden pt-5">
                <div className="container-fluid pt-5">
                    <div className="row">
                        <div className="col-lg-6 right-box text-center">
                            <div>
                                <h1 className="title wow fadeInUp" data-wow-delay="1.2s">
                                    {blogData.name}
                                </h1>
                            </div>
                        </div>

                        <div className="col-lg-6 left-box text-center">
                            <h3 className="title wow fadeInUp" data-wow-delay="1.2s">Reviews</h3>
                            <div className="rating-container">
                                <span
                                    id="average-rating"
                                    className="average-rating wow fadeInUp"
                                    data-wow-delay="1.2s"
                                >
                                    Avg Rating:
                                </span>
                                <div className="star-rating"></div>
                            </div>
                        </div>

                        <ul id="comment-list"></ul>
                    </div>
                </div>
            </section>

            <section className="content-inner-3 pt-5">
                <div className="container pt-5">
                    <div className="product-description">
                        <div className="dz-tabs">
                            <ul className="nav nav-tabs center" id="myTab1" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link active"
                                        id="home-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#home-tab-pane"
                                        type="button"
                                        role="tab"
                                        aria-controls="home-tab-pane"
                                        aria-selected="true"
                                    >
                                        Blog Details
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="home-tab-pane"
                                    role="tabpanel"
                                    aria-labelledby="home-tab"
                                    tabIndex="0"
                                >
                                    <div className="detail-bx text-center pb-3">
                                        <span className="para-text">
                                            {ReactHtmlParser(String(blogData.description))}
                                        </span>
                                        <div className="dz-media rounded">
                                            <img
                                                src={`${imageUrl}${blogData.image}`}
                                                alt="Blog Image"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BlogDetail;
