import React, { Component, Fragment } from 'react'
import Footer from '../components/Footer/Footer'
import TopBar from '../components/TopBar/Topbar'
import CategoryDetail from '../components/CategoryDetail/CategoryDetail'
import Products from '../components/Products/Products';

class CategoryPage extends Component {
  constructor({match}){
    super();
    this.state={
      CategoryPassedSlug:match.params.categorySlug
    }
  }
  render() {
    return (
      <Fragment>
        <TopBar />
        <CategoryDetail id={this.state.CategoryPassedSlug} />
        <Footer />
      </Fragment>
    )
  }
}

export default CategoryPage   