import React, { useState, useContext } from 'react';
import Swal from 'sweetalert2';
import AppUrl from '../../RestAPI/AppUrl';
import { CartContext } from '../CartContext/CartContext';
import RestClient from '../../RestAPI/RestClient';
import { Helmet } from 'react-helmet-async';

const Checkout = () => {
  const { cart, clearCart } = useContext(CartContext);
  const [formState, setFormState] = useState({
    name: '',
    address: '',
    phone: '',
    city: '',
    note: '',
  });
  const [loading, setLoading] = useState(false);

  const SHIPPING_COST = 199; // Fixed shipping cost

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { name, address, phone, city, note } = formState;
    const total = cart.reduce((total, item) => {
      const itemPrice = item.sale_price || item.price;
      return total + itemPrice * item.quantity;
    }, 0).toFixed(2);

    const totalAmountWithShipping = (parseFloat(total) + SHIPPING_COST).toFixed(2); // Add shipping cost

    const orderData = {
      items: cart.map((item) => ({
        product_id: item.id,
        quantity: item.quantity,
        price: item.sale_price || item.price,
      })),
      name,
      address,
      phone,
      city,
      note,
      total_amount: totalAmountWithShipping, // Send the total amount including shipping
    };

    try {
      await RestClient.PostRequest(AppUrl.Checkout, orderData);

      Swal.fire({
        icon: 'success',
        title: 'Order placed successfully!',
        text: 'Your order has been placed and is being processed.',
      });

      clearCart();
      window.location.href = '/order-confirm';
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong while placing your order!',
      });
    } finally {
      setLoading(false);
    }
  };

  const totalAmount = cart.reduce((total, item) => {
    const itemPrice = item.sale_price || item.price;
    return total + itemPrice * item.quantity;
  }, 0).toFixed(2);

  const totalAmountWithShipping = (parseFloat(totalAmount) + SHIPPING_COST).toFixed(2); // Add shipping cost

  return (
    <>
      <Helmet>
        <title>Checkout Page</title>
        <meta name="format-detection" content="telephone=no" />
        <meta name="keywords" content="Checkout Page" />
        <meta name="description" content="Checkout Page" />
      </Helmet>

      <div className="content-inner-1 pt-5">
        <div className="container pt-5">
          <div className="row shop-checkout pt-3">
            <div className="col-xl-8">
              <h4 className="title m-b15">Checkout</h4>
              <form className="row" onSubmit={handleSubmit}>
                {['name', 'address', 'phone', 'city'].map((field) => (
                  <div className="col-md-12" key={field}>
                    <div className="form-group m-b25">
                      <label className="label-title">{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                      <input
                        name={field}
                        value={formState[field]}
                        onChange={handleInputChange}
                        required
                        placeholder={`Your ${field}`}
                        className="form-control"
                      />
                    </div>
                  </div>
                ))}
                <div className="col-md-12 m-b25">
                  <div className="form-group">
                    <label className="label-title">Order notes (optional)</label>
                    <textarea
                      name="note"
                      value={formState.note}
                      onChange={handleInputChange}
                      placeholder="Notes about your order, e.g. special notes for delivery."
                      className="form-control"
                      rows="5"
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                  {loading ? 'Placing Order...' : 'Place Order'}
                </button>
              </form>
            </div>

            <div className="col-xl-4 side-bar">
              <div className="order-detail sticky-top">
                {cart.map((item) => (
                  <div className="cart-item style-1" key={item.id}>
                    <div className="dz-media">
                      <img src={`${process.env.REACT_APP_BASE_URL}${item.image}`} alt={item.name} />
                    </div>
                    <div className="dz-content">
                      <h6 className="title mb-0">{item.name} x ({item.quantity})</h6>
                      <span className="price">Rs/{((item.sale_price || item.price) * item.quantity).toFixed(2)}</span>
                    </div>
                  </div>
                ))}
                <table>
                  <tbody>
                    <tr className="total">
                      <td>Total</td>
                      <td className="price">Rs/{totalAmount}</td>
                    </tr>
                    <tr className="shipping">
                      <td>Shipping</td>
                      <td className="price">Rs/{SHIPPING_COST}</td>
                    </tr>
                    <tr className="total-with-shipping">
                      <td><strong>Total with Shipping</strong></td>
                      <td className="price"><strong>Rs/{totalAmountWithShipping}</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
