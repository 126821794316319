import React, { Component, Fragment } from 'react'
import Footer from '../components/Footer/Footer'
import OrderConfirm from '../components/OrderConfirm/OrderConfirm'
import TopBar from '../components/TopBar/Topbar'

class ShopPage extends Component {
  render() {
    return (
      <Fragment>
        <TopBar />
        <OrderConfirm />
        <Footer />
      </Fragment>
    )
  }
}

export default ShopPage