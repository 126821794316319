import React, { Component, Fragment } from 'react'
import Footer from '../components/Footer/Footer'
import Products from '../components/Products/ShopProducts'
import TopBar from '../components/TopBar/Topbar'

class ShopPage extends Component {
  render() {
    return (
      <Fragment>
        <TopBar />
        <Products />
        <Footer />
      </Fragment>
    )
  }
}

export default ShopPage