import React, { Component, Fragment } from 'react'
import Useful from '../components/Useful/UsefulOne'
import Footer from '../components/Footer/Footer'
import TopBar from '../components/TopBar/Topbar'

class PolicyPage extends Component {
  render() {
    return (
      <Fragment>
        <TopBar />
        <Useful />
        <Footer />
      </Fragment>
    )
  }
}

export default PolicyPage 