import React, { createContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Create a context for the cart
export const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    // Load cart from localStorage on initial load
    const savedCart = localStorage.getItem('cart');
    try {
      return savedCart ? JSON.parse(savedCart) : [];
    } catch (e) {
      console.error('Error parsing cart from localStorage:', e);
      return [];
    }
  });

  // Update localStorage whenever the cart changes
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  // Add a product to the cart
  const addToCart = (product) => {
    if (!product.id) {
      Swal.fire({
        icon: 'error',
        title: 'Product ID Missing!',
        text: 'Cannot add this product to the cart. Please try again.',
        confirmButtonText: 'Okay',
      });
      return;
    }
  
    // Check if the product is already in the cart
    const productExists = cart.some((cartItem) => cartItem.id === product.id);
    if (productExists) {
      Swal.fire({
        icon: 'warning',
        title: 'Already in Cart!',
        text: `${product.name} is already in your cart.`,
        confirmButtonText: 'Okay',
      });
      return;
    }
  
    // Add product to the cart with quantity and final_price
    setCart((prevCart) => [...prevCart, { ...product, quantity: 1 }]);
  
    // Display success message
    Swal.fire({
      icon: 'success',
      title: 'Added to Cart!',
      text: `${product.name} has been added to your cart.`,
      showCancelButton: true,
      cancelButtonText: 'Continue Shopping',
      confirmButtonText: 'Proceed to Checkout',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = '/checkout';
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        window.location.href = '/shop';
      }
    });
  
    console.log('Product added to cart:', product);
  };
  

  // Remove a product from the cart
  const removeFromCart = (productId) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== productId));
  };

  // Update product quantity in the cart
  const updateQuantity = (productId, quantity) => {
    if (quantity < 1) {
      Swal.fire({
        icon: 'warning',
        title: 'Invalid Quantity',
        text: 'Quantity must be at least 1.',
        confirmButtonText: 'Okay',
      });
      return;
    }

    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === productId ? { ...item, quantity } : item
      )
    );
  };

  // Clear the cart
  const clearCart = () => {
    setCart([]);
  };

  // Get the total number of items in the cart
  const cartCount = cart.reduce((total, item) => total + item.quantity, 0);

  return (
    <CartContext.Provider
      value={{
        cart,
        cartCount,
        addToCart,
        updateQuantity,
        removeFromCart,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
