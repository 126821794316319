
class AppUrl {
    static BaseURL = 'https://lightgray-gnu-792094.hostingersite.com/api/';
    static homebannerproduct = this.BaseURL+'homebannerproduct';
    static homeproduct = this.BaseURL+'homeproduct';
    static HomeBlog = this.BaseURL+'homeblog';
    static Setting = this.BaseURL+'setting';

    static ProductDetails = this.BaseURL+'product/';
    static CategoryProduct = this.BaseURL+'category/';
    static BlogDetail = this.BaseURL+'blog/'; 
    static Checkout = this.BaseURL+'checkout-order';
    static BuyNow = this.BaseURL+'buy-now';   
    static ReviewSubmit = this.BaseURL+'review/submit';   
    static Categories = this.BaseURL+'categories';   
}

export default AppUrl