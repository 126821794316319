import React, { Component, Fragment } from 'react'
import Footer from '../components/Footer/Footer'
import TopBar from '../components/TopBar/Topbar'
import Categories from '../components/Categories/AllCategories'

class CategoriesPage extends Component {
  render() {
    return (
      <Fragment>
        <TopBar />
        <Categories />
        <Footer />
      </Fragment>
    )
  }
}

export default CategoriesPage 