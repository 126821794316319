import React, { Component, Fragment } from 'react'
import {BrowserRouter as Router,Switch, Route, Link} from "react-router-dom";
import HomePage from '../pages/HomePage';
import ShopPage from '../pages/ShopPage';
import AboutPage from '../pages/AboutPage';
import PolicyPage from '../pages/PolicyPage';
import ShippingPage from '../pages/ShippingPage';
import TermsPage from '../pages/TermsPage';
import ContactPage from '../pages/ContactPage';
import CartPage from '../pages/CartPage';
import CheckoutPage from '../pages/CheckoutPage';
import OrderConfirmPage from '../pages/OrderConfirmPage';
import ProductDetailPage from '../pages/ProductDetailPage';
import CategoryPage from '../pages/CategoryPage';
import BlogDetail from '../pages/BlogPage';
import Categories from '../pages/CategoriesPage';


class AppRouter extends Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <Route exact path="/" component={HomePage} /> 
          <Route exact path="/shop" component={ShopPage} /> 
          <Route exact path="/about" component={AboutPage} /> 
          <Route exact path="/contact" component={ContactPage} /> 
          <Route exact path="/cart" component={CartPage} /> 
          <Route exact path="/checkout" component={CheckoutPage} /> 
          <Route exact path="/order-confirm" component={OrderConfirmPage} /> 
          <Route exact path="/product/:productSlug" component={ProductDetailPage} /> 
          <Route exact path="/category/:categorySlug" component={CategoryPage} /> 
          <Route exact path="/blog/:blogID" component={BlogDetail} /> 
          <Route exact path="/categories" component={Categories} />
          <Route exact path="/policy" component={PolicyPage} />
          <Route exact path="/shipping" component={ShippingPage} />
          <Route exact path="/terms" component={TermsPage} />
        </Switch>
      </Fragment>
    )
  }
}

export default AppRouter