import React, { Component, Fragment } from 'react'
import Footer from '../components/Footer/Footer'
import TopBar from '../components/TopBar/Topbar'
import Cart from '../components/Cart/Cart'

class CartPage extends Component {
  render() {
    return (
      <Fragment>
        <TopBar />
        <Cart />
        <Footer />
      </Fragment>
    )
  }
}

export default CartPage