import React, { useState, useEffect } from 'react';
import map from '../../assets/images/map/map.png';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import { Helmet } from 'react-helmet-async';

const Contact = () => {
  const [contactInfo, setContactInfo] = useState({
    address: '...',
    phone: '...',
    email: '...',
  });

  useEffect(() => {
    RestClient.GetRequest(AppUrl.Setting)
      .then(result => {
        setContactInfo({
          address: result[0]['address'],
          phone: result[0]['phone'],
          email: result[0]['email'],
        });
      })
      .catch(() => {
        setContactInfo({
          address: '????',
          phone: '????',
          email: '????',
        });
      });
  }, []); // Empty array ensures this effect runs once after the initial render

  return (

    <>
    <Helmet>
    <title>Contact us</title>
    <meta name="robots" content="index, follow" />
    <meta name="format-detection" content="telephone=no" />

    {/* Ensure keywords are populated */}
    <meta name="keywords" content="Contact us"/>

    {/* Ensure description is properly populated and of sufficient length */}
    <meta 
        name="description" 
        content="Contact us"
    />

</Helmet>
    

    <section className="bg-light content-inner-1 p-t70 contact-us2 overflow-hidden pt-5">
      <div className="container pt-5">
        <div className="row">
          <div className="map-fixed">
            <div className="contact-map">
              <img src={map} alt="/" />
              <nav>
                <div className="map-point nav" id="nav-tab" role="tablist">
                  <a
                    href="nav-1"
                    className="point point-1 active"
                    id="nav-tab1"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-tab-1"
                    role="tab"
                    aria-selected="true"
                  />
                  <a
                    href="nav-2"
                    className="point point-2"
                    id="nav-tab2"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-tab-2"
                    role="tab"
                    aria-selected="false"
                  />
                  <a
                    href="nav-3"
                    className="point point-3"
                    id="nav-tab3"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-tab-3"
                    role="tab"
                    aria-selected="false"
                  />
                </div>
              </nav>
            </div>
            <div className="dz-text wow fadeInUp" data-wow-delay="0.5s">
              Contact Us
            </div>
          </div>
          <div className="col-xl-5 col-md-7">
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-tab-1" role="tabpanel" aria-labelledby="nav-tab1" tabindex="0">
                <div className="contact-info style-1 text-start text-white">
                  <h2 className="title wow fadeInUp" data-wow-delay="0.1s">Pakistan</h2>
                  <p className="text wow fadeInUp" data-wow-delay="0.2s">
                    <span>Address:</span> {contactInfo.address}
                  </p>
                  <div className="contact-bottom wow fadeInUp justify-content-between" data-wow-delay="0.3s">
                    <div className="contact-left">
                      <h3>Call Us</h3>
                      <ul>
                        <li>{contactInfo.phone}</li>
                      </ul>
                    </div>
                    <div className="contact-right">
                      <h3>Email Us</h3>
                      <ul>
                        <li>{contactInfo.email}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default Contact;
