import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from './router/AppRouter';
import CartProvider from '../src/components/CartContext/CartContext';  // Import CartProvider
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <CartProvider>
    <HelmetProvider>
    <BrowserRouter>     
        <AppRouter />     
    </BrowserRouter>
    </HelmetProvider>
    </CartProvider>
  );
}

export default App;
