import React, { useState, useEffect } from 'react';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import ReactHtmlParser from 'html-react-parser';
import { Helmet } from 'react-helmet-async';

const Useful = () => {
  const [shipping_policy, setAbout] = useState("...");

  useEffect(() => {
    RestClient.GetRequest(AppUrl.Setting)
      .then(result => {
        setAbout(result[0]['shipping_policy']);
      })
      .catch(() => {
        setAbout("????");
      });
  }, []); // Empty dependency array ensures this runs once on component mount

  return (

    <>
    
    <Helmet>
    <title>Shipping Policy</title>
    <meta name="robots" content="index, follow" />
    <meta name="format-detection" content="telephone=no" />

    {/* Ensure keywords are populated */}
    <meta name="keywords" content="Shipping Policy"/>

    {/* Ensure description is properly populated and of sufficient length */}
    <meta 
        name="description" 
        content="Shipping Policy"
    />

</Helmet>
    
    

    <section className="dz-bnr-inr dz-bnr-inr-sm bg-light pt-5">
      <div className="container">
        <div className="dz-bnr-inr-entry">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-7">
              <div className="text-start mb-xl-0 mb-4">
               <ul><li>{ReactHtmlParser(String(shipping_policy))}</li></ul>
              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="about-sale text-start">
                <div className="row">
                  <div className="col-lg-5 col-md-6 col-6 me-5">
                    <div className="about-content">
                      <h2 className="title"><span className="counter">5000</span>+</h2>
                      <p className="text">Items Sale Per Month</p>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 col-6">
                    <div className="about-content">
                      <h2 className="title">90%</h2>
                      <p className="text">Delivery Ratio</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default Useful;
