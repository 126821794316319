import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import ReactHtmlParser from 'html-react-parser';
import { ClipLoader } from 'react-spinners';
import { Helmet } from 'react-helmet-async';

const CategoryDetail = ({ id }) => {
  const [products, setProducts] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState(12);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await RestClient.GetRequest(AppUrl.CategoryProduct + id);
        if (Array.isArray(result)) {
          setProducts(result);

          // Find the matching category for the provided `id`
          const matchedCategory = result[0]?.categories?.find((cat) => cat.slug === id);
          setCategory(matchedCategory || null);
        } else {
          setProducts([]);
          setCategory(null);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setProducts([]);
        setCategory(null);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const getProductImageUrl = (product) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL || '';
    const ImUrl = process.env.REACT_APP_IM_URL || '';
    return product.image ? `${ImUrl}${product.image}` : `${BASE_URL}/uploads/no-img.jpg`;
  };

  const calculateRating = (reviews = []) => {
    const totalRating = reviews.reduce((sum, { rating }) => sum + rating, 0);
    const averageRating = reviews.length > 0 ? (totalRating / reviews.length).toFixed(1) : 0;
    return { averageRating, reviewCount: reviews.length };
  };

  const renderPrice = ({ sale_price, price }) => (
    <>
      {sale_price ? (
        <>
          <span className="sale-price font-14">Rs/{sale_price}</span>
          <span className="original-price font-14">
            <del>Rs/{price}</del>
          </span>
        </>
      ) : price ? (
        <span className="price font-14">Rs/{price}</span>
      ) : (
        <span className="price font-14">Rs/N/A</span>
      )}
    </>
  );
  
 
  const imageStyle = {
    width: "200px", // Adjust the width as per your layout
    height: "200px", // Set a fixed height
    objectFit: "cover", // Ensures the image scales without distortion
    borderRadius: "8px", // Optional: Add rounded corners
  };

  const loadMoreProducts = () => {
    setVisibleProducts((prevVisible) => prevVisible + 12);
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <ClipLoader color="#000" loading size={60} />
        <p>Loading...</p>
      </div>
    );
  }

  if (products.length === 0 || !category) {
    return (
      <div style={{ textAlign: 'center', marginTop: '80px' }}>
        <h3>Oops! No Products Found</h3>
      </div>
    );
  }

  const { averageRating, reviewCount } = calculateRating(products[0]?.reviews || []);

  return (
    <>
      {/* Meta tags for SEO */}
      <Helmet>
        <title>{category.name ? `${category.name} | React` : 'Default Title'}</title>
        <meta name="robots" content="index, follow" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="keywords" content={category.meta_keywords || 'default, keywords'} />
        <meta name="description" content={category.meta_description || 'Default description'} />
        <link rel="canonical" href={`/category/${id}`} />
      </Helmet>

      {/* Header Section */}
      <section className="shop-section overflow-hidden pt-5">
        <div className="container-fluid pt-5">
          <div className="row">
            <div className="col-lg-6 right-box text-center">
              <h1 className="title">{category.name}</h1>
            </div>
            <div className="col-lg-6 left-box text-center">
              <h3>Reviews ({reviewCount})</h3>
              <div className="rating-container">
                <span>Rating: {averageRating}</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Product List */}
      <section className="content-inner">
        <div className="container">
          <h2 className="title text-center mb-5">{category.name} Products</h2>
          <div className="row g-3">
            {products.slice(0, visibleProducts).map((product) => (
              <div className="col-6 col-lg-3" key={product.id}>
                <div className="shop-card">
                  <Link to={`/product/${product.slug}`}>
                    <div className="dz-media">
                      <img src={getProductImageUrl(product)} style={imageStyle} alt={product.name} />
                    </div>
                  </Link>
                  <div className="dz-content">
                    <h5><Link to={`/product/${product.slug}`}>{product.name.slice(0, 20)}...</Link></h5>
                    <h5 className="price">{renderPrice(product)}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {visibleProducts < products.length && (
            <div className="text-center">
              <button className="btn btn-secondary" onClick={loadMoreProducts}>
                Load More
              </button>
            </div>
          )}
        </div>
      </section>

      {/* Product Description */}
      <section className="content-inner-3 pb-5">
        <div className="container">
          <div className="product-description">
            <div className="dz-tabs">
              <ul className="nav nav-tabs center" role="tablist">
                <li className="nav-item">
                  <button className="nav-link active" data-bs-toggle="tab">
                    Description
                  </button>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane fade show active">
                  {ReactHtmlParser(category.description || 'No Description Available')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CategoryDetail;
