import React, { Component } from 'react'
import img2 from '../../assets/images/circle-lines4.png'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Helmet } from 'react-helmet-async'

class OrderConfirm extends Component {
  render() {
    return (
      <>
	  	<Helmet>
    <title>Order Confirm Page</title>
    <meta name="robots" content="index, follow" />
    <meta name="format-detection" content="telephone=no" />

    {/* Ensure keywords are populated */}
    <meta name="keywords" content="Order Confirm Page"/>

    {/* Ensure description is properly populated and of sufficient length */}
    <meta 
        name="description" 
        content="Order Confirm Page"
    />

</Helmet>
	  
        <section className="overflow-hidden pt-5">
			<div className="row error-page style-2">
				
				<div className="col-lg-12 col-md-12 error-end-content">
					<div className="error-inner">
						<h1 className="error-head">Thanks You</h1>
						<p className="error-para">Your Order Has Been Succesfully Recieved</p>
						<Link to="/" className="btn btn-secondary  text-uppercase">Go to home</Link>
					</div>
					<img src={img2} className="bg-img2" alt="bg-img2" />
				</div>
			</div>
		</section>
		</>
    )
  }
}

export default OrderConfirm