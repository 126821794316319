import React, { Component, Fragment } from 'react'
import Footer from '../components/Footer/Footer'
import TopBar from '../components/TopBar/Topbar'
import BlogDetail from '../components/BlogDetail/BlogDetail';
import Products from '../components/Products/Products';

class BlogPage extends Component {
  constructor({match}){
    super();
    this.state={
      BlogPassedID:match.params.blogID
    }
  }
  render() {
    return (
      <Fragment>
        <TopBar />
        <BlogDetail id={this.state.BlogPassedID} />
        <Products />
        <Footer />
      </Fragment>
    )
  }
}

export default BlogPage   