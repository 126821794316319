import React, { Component, Fragment } from 'react'
import Banner from '../components/Banner/Banner'
import Products from '../components/Products/Products'
import Blogs from '../components/Blogs/Blogs'
import Footer from '../components/Footer/Footer'
import TopBar from '../components/TopBar/Topbar'

class HomePage extends Component {
  render() {
    return (
      <Fragment>
        <TopBar />
        <Banner />
        <Products />
        <Blogs />
        <Footer />
      </Fragment>
    )
  }
}

export default HomePage