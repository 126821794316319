import React, { useContext, useEffect, useState } from 'react';
import ReactHtmlParser from 'html-react-parser';
import { Button, Form, Modal } from 'react-bootstrap';
import { CartContext } from '../CartContext/CartContext'; // Context for Cart
import AppUrl from '../../RestAPI/AppUrl'; // API endpoint
import RestClient from '../../RestAPI/RestClient'; // API client
import img from '../../assets/images/profile4.jpg'; // Default avatar
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Helmet } from 'react-helmet-async';

const ProductDetail = ({ slug }) => {
  const { addToCart } = useContext(CartContext);

  const [productDetails, setProductDetails] = useState({
    id: null,
    name: null,
    slug: null,
    price: null,
    sale_price: null,
    quantity: null,
    tags: [],
    image: null,
    gallery_images: [],
    description: null,
    meta_keywords: null,
    meta_description: null,
    reviews: [],
    categories: [],
  });

  const [formData, setFormData] = useState({
    name: '',
    city: '',
    address: '',
    phone: '',
    qty: 1,
  });

  const initialReviewData = {
    name: '',
    email: '',
    review: '',
    rating: '', // Reset the rating as well
  };

  const [Reviewdata, reviewFormData] = useState(initialReviewData);

  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const result = await RestClient.GetRequest(AppUrl.ProductDetails + slug);
        if (result?.[0]) {
          const product = result[0];
          setProductDetails({
            id: product.id,
            name: product.name,
            slug: product.slug,
            price: product.price,
            sale_price: product.sale_price,
            quantity: product.quantity,
            tags: JSON.parse(product.tags || '[]'),
            image: product.image,
            gallery_images: JSON.parse(product.gallery_images || '[]'),
            description: product.description,
            reviews: product.reviews || [],
            categories: product.categories || [],
            meta_keywords: JSON.parse(product.meta_keywords || '[]'),
            meta_description: product.meta_description || {},
          });
        } else {
          console.warn('Product details not found');
        }
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    };
    fetchProductDetails();
  }, [slug]);

  const handleAddToCart = () => {
    const productToAdd = {
      ...productDetails, // Include all other product details
      final_price: productDetails.sale_price || productDetails.price, // Use sale_price if available; otherwise, price
    };
  
    addToCart(productToAdd);
  };
  
  

  // Handle form input change
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  
  // Handle buy now
  const handleBuyNow = async (e) => {
    e.preventDefault();
    const shippingCost = 199; // Fixed shipping cost
  
    // Calculate the total price including shipping
    const totalPrice = productPrice * formData.qty + shippingCost;
  
    try {
      const orderData = { 
        product_id: productDetails.id,
        product_price: productPrice,
        shipping_price: shippingCost, // Include shipping cost in the order data
        total_price: totalPrice, // Include total price (product price + shipping)
        ...formData,
      };
  
      const response = await RestClient.PostRequest(AppUrl.BuyNow, orderData);
  
      if (response?.status === 'success') {
        Swal.fire('Success', 'Your order has been placed successfully!', 'success');
        setShowModal(false);
      } else {
        Swal.fire('Error', 'Failed to place your order. Please try again.', 'error');
      }
    } catch (error) {
      console.error('Error placing order:', error);
      Swal.fire('Error', 'An unexpected error occurred.', 'error');
    }
  }


  const handleReviewChange = (e) => {
    reviewFormData({ ...Reviewdata, [e.target.name]: e.target.value });
  };

  const handleRatingChange = (value) => {
    reviewFormData({ ...Reviewdata, rating: value });
  };
  


  const SubmitReview = async (e) => {
    e.preventDefault();
    try {
      const orderData = {
        product_id: productDetails.id,
        ...Reviewdata,
      };

      const response = await RestClient.PostRequest(AppUrl.ReviewSubmit, orderData);

      if (response?.status === 'success') {
        Swal.fire('Success', 'Your review submitted successfully!', 'success');
        reviewFormData(initialReviewData);
      } else {
        Swal.fire('Error', 'Oops Something Went Wrong. Please try again.', 'error');
      }
    } catch (error) {
      console.error('Error placing order:', error);
      Swal.fire('Error', 'An unexpected error occurred.', 'error');
    }
  };


  const calculateAverageRating = (reviews) => {
    if (!reviews?.length) return 0;
    const total = reviews.reduce((sum, { rating }) => sum + rating, 0);
    return (total / reviews.length).toFixed(1);
  };

  const renderReviews = () =>
    productDetails.reviews.map((review, index) => (
      <div key={index} className="dz-review" style={style.reviewContainer}>
        <div className="dz-review-info" style={style.reviewInfo}>
          <h6 style={style.customerName}>Customer: {review.name}</h6>
          <p style={style.reviewText}>Review: {review.review}</p>
          <div className="rating-stars" style={style.ratingContainer}>
            <ul id="stars" style={style.starList}>
              {[...Array(5)].map((_, starIndex) => (
                <li key={starIndex} className="star mb-2" style={style.starItem}>
                  <i
                    className={`flaticon-star-1 ${starIndex < review.rating ? 'star-fill' : ''}`}
                    style={{
                      ...style.starIcon,
                      color: starIndex < review.rating ? '#FFD700' : '#d3d3d3', // Gold for filled stars, gray for empty stars
                    }}
                  ></i>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    ));

    const style = {
      reviewContainer: {
        marginBottom: '20px',
        padding: '15px',
        border: '5px solid #fff',
        borderRadius: '20px',
        backgroundColor: '#000',
      },
      reviewInfo: {
        paddingLeft: '10px',
      },
      customerName: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#fff',
      },
      reviewText: {
        fontSize: '16px',
        color: '#fff',
        marginTop: '10px',
      },
      ratingContainer: {
        marginTop: '5px',
      },
      starList: {
        listStyleType: 'none',
        padding: 0,
        display: 'inline-flex',
        justifyContent: 'center',
      },
      starItem: {
        marginRight: '1px',
        marginTop: '-15px',
      },
      starIcon: {
        fontSize: '20px',
        transition: 'color 0.3s ease', // Smooth transition for color change
      },
    };

    const styles = {
      
      starList: {
        listStyleType: 'none',
        padding: 0,
        display: 'inline-flex',
        justifyContent: 'center',
      },
      star: {
        fontSize: '20px',
        marginRight: '2px',
        marginBottom: '10px',
        transition: 'color 0.3s ease', // Smooth transition for color change
      },
      
    };

    const productPrice = productDetails.sale_price ? productDetails.sale_price : productDetails.price;


  return (
    <>

<Helmet>
    <title>{productDetails && productDetails.name ? `${productDetails.name} | React` : 'Default Title'}</title>

    <meta name="robots" content="index, follow" />
    <meta name="format-detection" content="telephone=no" />

    {/* Ensure keywords are populated */}
    <meta name="keywords" content={productDetails.meta_keywords || 'default, keywords, for, product'} />

    {/* Ensure description is properly populated and of sufficient length */}
    <meta 
        name="description" 
        content={productDetails.meta_description}
    />

    {/* Ensure canonical URL is properly set */}
    <meta rel="canonical" href={`/product/${productDetails?.id || ''}`} />

</Helmet>

<section class="content-inner pt-5 py-0">
			<div class="container-fluid pt-2">
				<div class="row">
        
        <div className="col-xl-6 col-md-6 pt-5">
  <div className="dz-product-detail sticky-top">
    <div className="swiper-btn-center-lr">
      <div className="swiper product-gallery-swiper2">
        <div className="swiper-wrapper" id="lightgallery">
          {productDetails.gallery_images.slice(0, 4).map((galleryImg, index) => (
            <div className="swiper-slide" key={index}>
              <div className="dz-media DZoomImage rounded">
                <a
                  className="mfp-link lg-item"
                  href={`${process.env.REACT_APP_BASE_URL}${galleryImg}`}
                  data-src={`${process.env.REACT_APP_BASE_URL}${galleryImg}`}
                >
                  <i className="feather icon-maximize dz-maximize top-right"></i>
                </a>
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${galleryImg}`}
                  alt={`image-${index}`}
                  
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="swiper product-gallery-swiper thumb-swiper-lg swiper-vertical">
        <div className="swiper-wrapper">
          {productDetails.gallery_images.slice(0, 4).map((galleryImg, index) => (
            <div className="swiper-slide" key={index}>
              <img
                src={`${process.env.REACT_APP_BASE_URL}${galleryImg}`}
                alt={`thumb-${index}`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
        </div>
            

					<div class="col-xl-6 col-md-6">
						<div class="dz-product-detail style-2 p-t50">
							<div class="dz-content">
								<div class="dz-content-footer">
									<div class="dz-content-start">
										<span class="badge bg-secondary mb-2">Trending item</span>
										<h4 class="title mb-1">{productDetails.name}</h4>
										<div class="review-num">
											<ul class="dz-rating me-2">
												{[...Array(5)].map((_, index) => (
                                <li
                                  key={index}
                                  className={index < calculateAverageRating(productDetails.reviews) ? 'star-fill' : ''}
                                >
                                  <i className="flaticon-star-1"></i>
                                </li>
                              ))}
											</ul>
											<span class="text-secondary me-2">{calculateAverageRating(productDetails.reviews)} Rating</span>
											<span>({productDetails.reviews.length} customer reviews)</span>
										</div>
									</div>
								</div>
								
								<div class="meta-content m-b20 d-flex align-items-end">
									<div class="me-3">
										<span class="form-label">Price</span>
										<span className="price">
                      {productDetails.sale_price ? (
                        <>
                          Rs {productDetails.sale_price} <del>Rs {productDetails.price}</del>
                        </>
                      ) : (
                        <>Rs {productDetails.price}</>
                      )}
                    </span>

									</div>
								
								</div>
								
								<div class="btn-group cart-btn">
									
									<Button
                      onClick={handleAddToCart}
                      className="btn btn-secondary text-uppercase"
                      type="button"
                    >
                      Add to Cart
                    </Button>
                    <Button
                    className="btn btn-secondary text-uppercase"
                    onClick={() => setShowModal(true)}
                  >
                    Buy Now
                  </Button>
								</div>
								<div class="dz-info">
                <ul>
									<li><strong>Categories:</strong></li>
                  {productDetails.categories && productDetails.categories.length > 0 ? (
                    productDetails.categories.map((category, index) => (
                      <li className="price" key={index}>
                        <Link to={`/category/${category.slug}`}>
                          {category.name}
                        </Link>
                      </li>
                    ))
                  ) : (
                    <li className="price">
                      <span>No categories available</span>
                    </li>
                  )}							
									</ul>
									
									<ul>
										<li><strong>Tags:</strong></li>
				                          {productDetails.tags.map((tag, index) => (
				                            <li key={index} className='price'>{tag}</li>
				                          ))}							
									</ul>
								</div>
								<ul class="d-md-flex d-none align-items-center">
									<li class="icon-bx-wraper style-3 me-xl-4 me-2">
										<div class="icon-bx">
											<i class="flaticon flaticon-ship"></i>
										</div>
										<div class="info-content">
											<span>FREE</span>
											<h6 class="dz-title mb-0">Shipping</h6>
										</div>
									</li>
									
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
      <section className="content-inner-3 pb-5">
        <div className="container">
          <div className="product-description">
            <div className="dz-tabs">
              <ul className="nav nav-tabs center" id="myTab1" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="home-tab-pane"
                    aria-selected="true"
                  >
                    Description
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="profile-tab-pane"
                    aria-selected="false"
                  >
                    Reviews
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTab1Content">
                <div
                  className="tab-pane fade show active"
                  id="home-tab-pane"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="dz-description text-center">
                    <p>{productDetails.description ? ReactHtmlParser(productDetails.description) : 'No description available.'}</p>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile-tab-pane"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="dz-reviews text-center">
                    <h3>Customer Reviews</h3>
                    {renderReviews()}
                  </div>
                  
                  <div className="default-form comment-respond style-1 mt-3" id="respond">
      <h3 className="mb-2" id="reply-title">Review Form</h3>
      <div className="comment-form-rating d-flex">
        <h5 className="m-r5 m-b10 text-secondary">Your Rating:</h5>
        <div>
          <ul id="stars" style={styles.starList}>
            {[1, 2, 3, 4, 5].map((starValue) => (
              <li
                key={starValue}
                className="star"
                title={`Rating ${starValue}`}
                data-value={starValue}
                onClick={() => handleRatingChange(starValue)}
                style={{
                  ...styles.star,
                  color: starValue <= Reviewdata.rating ? '#FFD700' : '#d3d3d3', // Fill color for selected stars
                  cursor: 'pointer',
                }}
              >
                <i className="fas fa-star fa-fw"></i>
              </li>
            ))}
          </ul>
          
        </div>
      </div>
      <div className="clearfix">
        <form onSubmit={SubmitReview} className="comment-form">
          <p className="comment-form-author">
            <input
              id="name"
              placeholder="Name"
              name="name"
              type="text"
              value={Reviewdata.name}
              onChange={handleReviewChange}
              
            />
          </p>
          <p className="comment-form-email">
            <input
              id="email"
              required="required"
              placeholder="Email"
              name="email"
              type="email"
              value={Reviewdata.email}
              onChange={handleReviewChange}
              
            />
          </p>
          <p className="comment-form-comment">
            <textarea
              id="review"
              required="required"
              placeholder="Type Comment Here"
              name="review"
              value={Reviewdata.review}
              onChange={handleReviewChange}
              
            />
          </p>
          <p className="col-md-12 col-sm-12 col-xs-12 form-submit">
            <button
              id="submit"
              type="submit"
              className="submit btn btn-secondary btnhover3 filled"
              
            >
              Submit Now
            </button>
          </p>
        </form>
      </div>
    </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

       {/* Modal for Buy Now */}
       <Modal show={showModal} onHide={() => setShowModal(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title>Complete Your Purchase</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div>
      <img
        src={`${process.env.REACT_APP_BASE_URL}${productDetails.image}`}
        width={150}
        height={150}
        alt={`thumb-${productDetails.name}`}
      /><br /><br />
      <p className='title'>{productDetails.name}</p>
    </div>
    <Form onSubmit={handleBuyNow}> 
      <Form.Group>
        <Form.Control
          type="text"
          placeholder="Enter Your Name"
          name="name"
          className="mb-3"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Control
          type="text"
          placeholder="Enter Your City"
          name="city"
          className="mb-3"
          value={formData.city}
          onChange={handleInputChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Control
          type="text"
          name="address"
          className="mb-3"
          placeholder="Enter Your Full Address"
          value={formData.address}
          onChange={handleInputChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Control
          type="tel"
          placeholder="Enter Your Correct Number"
          className="mb-3"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          required
        />
      </Form.Group>
      <Form.Group>
  <Form.Control
    type="number"
    placeholder="Select Item Quantity?"
    className="mb-3"
    min={1}
    step={1} 
    name="qty"
    value={formData.qty}
    onChange={(e) =>
      setFormData({ ...formData, qty: parseInt(e.target.value, 10)})
    }
    inputMode="numeric"
    required
  />
</Form.Group>


      {/* Display total price including shipping */}
      <div className="total-price">
        <h5>Shipping Price: Rs/199</h5>
        <h5>Total Price: Rs/{(productPrice * formData.qty + 199).toFixed(2)}</h5>
      </div>
      
      <Button variant="secondary" type="submit" className="w-100 mt-3">
        Place Order
      </Button>
    </Form>
  </Modal.Body>
</Modal>
    </>
  );
};

export default ProductDetail
